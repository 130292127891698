import { request } from "../functions/request";
export function getTopBrands() {
  return request(`/brand/get_top.php`);
}
export function getAllBrands() {
  return request(`/brand/get_list.php`);
}
export async function getSeoBrands(url: string) {
  return request(`/seo/?url=brands/&name=${url}`);
}
export async function getNestedBrand(brand: string) {
  return request(`/catalog/get_items_list.php?filter[proizvoditel][]=${brand}&price_check=true`);
}
export async function getFiltersBrand(brand: string) {
  return request(`/catalog/get_filter.php?filter[proizvoditel][]=${brand}`);
}
export async function getInfoBrand(brand: string) {
  return request(`/brand/get_info.php?name=${brand}`);
}
export async function getCategories(brand: string) {
  return request(`/catalog/get_sub_section.php?filter[proizvoditel][]=${brand}`);
}
export async function getSectionBrand(section: string) {
  return request(`/catalog/get_items_list.php?filter[proizvoditel][]=${section}&price_check=true`);
}
export async function getFiltersSectionBrand(brand: string, section: string) {
  return request(`/catalog/get_filter.php?filter[proizvoditel][]=${brand}&section=${section}`);
}