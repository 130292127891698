import styles from "./index.module.scss";
import SkeletonLoader from "@/shared/components/skeleton-loader";
const SalesSkeleton = () => {
  return <section className={styles.section} data-sentry-component="SalesSkeleton" data-sentry-source-file="index.tsx">
      <h2 className={styles.title}>Акции</h2>
      <div className={styles.sales}>
        <div className={styles.salesGrid}>
          <SkeletonLoader containerTag={"div"} width={""} height={""} borderRadius={8} className={styles.sale} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
          <SkeletonLoader containerTag={"div"} width={""} height={""} borderRadius={8} className={styles.sale} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
          <SkeletonLoader containerTag={"div"} width={""} height={""} borderRadius={8} className={styles.sale} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </section>;
};
export default SalesSkeleton;