import styles from "./index.module.scss";
import SkeletonLoader from "@/shared/components/skeleton-loader";
const BrandsSkeleton = () => {
  return <section className={styles.section} data-sentry-component="BrandsSkeleton" data-sentry-source-file="index.tsx">
      <h2 className={"title"}>Популярные бренды</h2>
      <div className={styles.brands}>
        {Array.from({
        length: 7
      }).map((_, index) => <SkeletonLoader key={index + "_brand_skeleton_elem"} containerTag={"div"} width={""} height={""} borderRadius={8} className={styles.brand} />)}
      </div>
    </section>;
};
export default BrandsSkeleton;