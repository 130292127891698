import { request } from "../functions/request";
export async function getNew(get?: string) {
  return request(`/catalog/get_items_list.php?filter[labels][]=novinki${get ? `&${get}` : ``}&price_check=true`, {
    next: {
      revalidate: 60 * 60
    }
  });
}
export async function getCategories() {
  return request(`/catalog/get_sub_section.php?filter[labels][]=novinki`);
}
export async function getFiltersNew(section?: string) {
  if (section) {
    return request(`/catalog/get_filter.php?filter[labels][]=novinki&section=${section}`);
  } else {
    return request(`/catalog/get_filter.php?filter[labels][]=novinki`);
  }
}
export async function getSeoNew(url: string) {
  return request(`/seo/?url=new/&section=${url}`);
}