"use client";

import styles from "./index.module.scss";
import Image from "next/image";
import { IAllStories } from "@/shared/types/types";
import { li } from "@/utils/functions/li";
interface IStoriesElem {
  checked: boolean;
  onClick: () => void;
  story: IAllStories;
}
const StoriesElem = (props: IStoriesElem) => {
  const {
    checked,
    onClick,
    story
  } = props;
  return <div className={`${styles.stories_item} ${checked ? styles.is_checked : ""}`} onClick={onClick} data-sentry-component="StoriesElem" data-sentry-source-file="index.tsx">
      <div className={styles.image_container}>
        <Image className={styles.image} alt={story.name} src={li(story.picture)} width={400} height={400} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      </div>
      <p className={styles.stories_text}>{story.name}</p>
    </div>;
};
export default StoriesElem;