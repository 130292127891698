import styles from "./index.module.scss";
import SkeletonLoader from "@/shared/components/skeleton-loader";
const CategoriesSkeleton = () => {
  return <section className={styles.section} data-sentry-component="CategoriesSkeleton" data-sentry-source-file="index.tsx">
      <h2 className={styles.title}>Популярные категории</h2>
      <SkeletonLoader containerTag={"div"} className={styles.loader} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
      {/*<div className={styles.categories}>*/}
      {/*  {Array.from({ length: 6 }).map((_, i) => (*/}
      {/*    <div className={styles.category} key={i + "_category_skeleton_" + i}>*/}
      {/*      <SkeletonLoader containerTag={"div"} width={""} height={""} borderRadius={8} mb={8} />*/}
      {/*      <SkeletonLoader containerTag={"div"} width={"70%"} height={16} borderRadius={4} />*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}
    </section>;
};
export default CategoriesSkeleton;