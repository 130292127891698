import styles from "./index.module.scss";
import SkeletonLoader from "@/shared/components/skeleton-loader";
interface ISwiperCardListSkeleton {
  title?: string;
}
const SwiperCardListSkeleton = (props: ISwiperCardListSkeleton) => {
  const {
    title
  } = props;
  return <section className={styles.section} data-sentry-component="SwiperCardListSkeleton" data-sentry-source-file="index.tsx">
      {title && <h2 className={"title"}>{title}</h2>}
      <div className={styles.cards}>
        {Array.from({
        length: 6
      }).map((_, index) => <div className={styles.card} key={index + "_cards_list_skeleton" + title}>
            <SkeletonLoader containerTag={"div"} width={"100%"} height={200} borderRadius={8} mb={12} />
            <div className={styles.stars}>
              {Array.from({
            length: 5
          }).map((_, i) => <SkeletonLoader containerTag={"div"} width={16} height={16} borderRadius={4} key={i + "skeleton_loader_card_title"} />)}
            </div>
            <SkeletonLoader containerTag={"div"} width={"100%"} height={20} borderRadius={4} mb={5} />
            <SkeletonLoader containerTag={"div"} width={"100%"} height={20} borderRadius={4} mb={5} />
            <SkeletonLoader containerTag={"div"} width={"100%"} height={20} borderRadius={4} mb={5} />
            <SkeletonLoader containerTag={"div"} width={"40%"} height={25} borderRadius={4} />
          </div>)}
      </div>
    </section>;
};
export default SwiperCardListSkeleton;