"use client";

import moment from "moment";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";
interface INewTimer {
  activeTo: string;
}
const NewTimer = (props: INewTimer) => {
  const {
    activeTo
  } = props;
  const [time, setTime] = useState(moment(activeTo).diff(moment.now()));
  const [days, setDays] = useState(String(Math.floor(time / (1000 * 60 * 60 * 24))).padStart(2, "0"));
  const [hours, setHours] = useState(String(Math.floor(time % (1000 * 60 * 60 * 24) / (1000 * 60 * 60))).padStart(2, "0"));
  const [minutes, setMinutes] = useState(String(Math.floor(time / 1000 / 60 % 60)).padStart(2, "0"));
  const [seconds, setSeconds] = useState(String(Math.floor(time / 1000 % 60)).padStart(2, "0"));
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(moment(activeTo).diff(moment.now()));
      setDays(String(Math.floor(time / (1000 * 60 * 60 * 24))).padStart(2, "0"));
      setHours(String(Math.floor(time % (1000 * 60 * 60 * 24) / (1000 * 60 * 60))).padStart(2, "0"));
      setMinutes(String(Math.floor(time / 1000 / 60 % 60)).padStart(2, "0"));
      setSeconds(String(Math.floor(time / 1000 % 60)).padStart(2, "0"));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [time, days, hours, minutes, seconds, activeTo]);
  return <div className={styles.timer} data-sentry-component="NewTimer" data-sentry-source-file="index.tsx">
      <div className={styles.timerItems}>
        <div className={styles.timerItemContainer}>
          <div className={styles.timerTimeContainer}>
            <div className={styles.timerItem}>{isClient ? days[0] : 0}</div>
            <div className={styles.timerItem}>{isClient ? days[1] : 0}</div>
          </div>
          <p className={styles.timerItemText}>дней</p>
        </div>
        <div className={styles.timerItemContainer}>
          <div className={styles.timerTimeContainer}>
            <div className={styles.timerItem}>{isClient ? hours[0] : 0}</div>
            <div className={styles.timerItem}>{isClient ? hours[1] : 0}</div>
          </div>
          <p className={styles.timerItemText}>часов</p>
        </div>
        <div className={styles.timerItemContainer}>
          <div className={styles.timerTimeContainer}>
            <div className={styles.timerItem}>{isClient ? minutes[0] : 0}</div>
            <div className={styles.timerItem}>{isClient ? minutes[1] : 0}</div>
          </div>
          <p className={styles.timerItemText}>минут</p>
        </div>
        <div className={styles.timerItemContainer}>
          <div className={styles.timerTimeContainer}>
            <div className={styles.timerItem}>{isClient ? seconds[0] : 0}</div>
            <div className={styles.timerItem}>{isClient ? seconds[1] : 0}</div>
          </div>
          <p className={styles.timerItemText}>секунд</p>
        </div>
      </div>
    </div>;
};
export default NewTimer;