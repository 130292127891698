"use client";

import styles from "./index.module.scss";
import { FC, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "./swiper.scss";
import { IAllStories } from "@/shared/types/types";
import { useAppSelector } from "@/shared/redux/hooks";
import { getStoriesViewedList } from "@/shared/redux/features/storiesSlice";
import { googleViewStories } from "@/utils/api/google-analytic";
import { useMediaQuery } from "@/shared/hooks/useMediaQuery";
import StoriesElem from "@/shared/components/StoriesElem";
import SkeletonLoader from "@/shared/components/skeleton-loader";
import { useDisableScroll } from "@/hooks/useDisableScroll";
import dynamic from "next/dynamic";
const StoriesPopup = dynamic(() => import("@/shared/components/popups/stories-popup/stories-popup"));
const StoriesMobilePopup = dynamic(() => import("@/shared/components/popups/StoriesMobilePopup"));
interface IStories {
  stories: IAllStories[];
}
const Stories: FC<IStories> = props => {
  const {
    stories
  } = props;
  const storiesRef = useRef(null);
  const [showContentStories, setShowContentStories] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [focusedElem, setFocusedElem] = useState(0);
  const isMobile = useMediaQuery("(max-width: 767px)");
  const storiesChecked = useAppSelector(getStoriesViewedList);
  const {
    disableScroll,
    enableScroll
  } = useDisableScroll();
  const focusStory = (elemIndex: number) => {
    setShowContentStories(true);
    setFocusedElem(elemIndex);
    ym(42989679, "reachGoal", "viewStories");
    googleViewStories();
    disableScroll();
  };
  const close = () => {
    setShowContentStories(false);
    enableScroll();
  };
  if (!stories || !stories.length) return null;
  return <section className={styles.stories_wrap} data-sentry-component="Stories" data-sentry-source-file="index.tsx">
      {!isLoading ? <SkeletonLoader height={""} className={styles.skeleton} containerTag={"div"} /> : null}
      {showContentStories ? !isMobile ? <StoriesPopup onClose={close} focusedElem={focusedElem} storiesList={stories} /> : <StoriesMobilePopup onClose={close} focusedElem={focusedElem} storiesList={stories} /> : null}
      <Swiper className="swiper-stories--main" slidesPerView={"auto"} allowTouchMove={true} pagination={false} spaceBetween={isMobile ? 22 : 62} ref={storiesRef} centerInsufficientSlides={!isMobile} loop={false} navigation onInit={() => setIsLoading(true)} data-sentry-element="Swiper" data-sentry-source-file="index.tsx">
        {stories.map((item, index) => {
        const checked = !!storiesChecked.find(story => story === item.id);
        return <SwiperSlide key={`${index + "swiper-stories" + index + "swiperSlide"}`} className="stories_slide">
              <StoriesElem key={`${index + "swiper-stories" + "stories_elem"}`} checked={checked} onClick={() => focusStory(index)} story={item} />
            </SwiperSlide>;
      })}
      </Swiper>
    </section>;
};
export default Stories;