import { request } from "../functions/request";
export const getStories = () => {
  return request("/history/", {
    next: {
      revalidate: 60 * 60
    }
  });
};
export const getHui = (id: string | number) => {
  return request(`/history/?id=${id}`);
};