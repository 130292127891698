import styles from "./index.module.scss";
import SkeletonLoader from "@/shared/components/skeleton-loader";
const SeasonSkeleton = () => {
  return <section className={styles.section} data-sentry-component="SeasonSkeleton" data-sentry-source-file="index.tsx">
      <h2 className={"title"}>Готовимся к сезону</h2>
      <div className={styles.seasons}>
        {Array.from({
        length: 3
      }).map((_, i) => <div className={styles.season} key={i + "_season_skeleton_elem"}>
            <SkeletonLoader containerTag={"div"} width={"100%"} height={"100%"} borderRadius={8} mb={8} />
            <SkeletonLoader containerTag={"div"} width={"70%"} height={16} borderRadius={4} />
          </div>)}
      </div>
    </section>;
};
export default SeasonSkeleton;