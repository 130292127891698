"use client";

import { ReactNode, useEffect, useState } from "react";
import { useIntersectionObserver } from "@/shared/hooks/useIntersectionObserver";
interface LazyLoadUIProps {
  children: ReactNode;
  skeleton?: ReactNode;
  isBot?: boolean;
  threshold?: number;
}

/**
 * Компонент для ленивой загрузки UI элементов при скролле
 * @param children UI элементы для отображения
 * @param skeleton Компонент-скелетон для отображения во время загрузки (опционально)
 * @param isBot Флаг, указывающий, является ли пользователь ботом
 * @param threshold Порог видимости для IntersectionObserver (от 0 до 1)
 */
const LazyLoadUI = (props: LazyLoadUIProps) => {
  const {
    children,
    skeleton = null,
    isBot = false,
    threshold = 0.1
  } = props;
  const [ref, isIntersecting] = useIntersectionObserver({
    threshold
  });
  const [isVisible, setIsVisible] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [hasBeenIntersected, setHasBeenIntersected] = useState(false);
  useEffect(() => {
    setIsClient(true);
    if (isBot) {
      setIsVisible(true);
    }
  }, [isBot]);
  useEffect(() => {
    if (isIntersecting && !hasBeenIntersected) {
      setHasBeenIntersected(true);
      setIsVisible(true);
    }
  }, [isIntersecting, hasBeenIntersected]);
  if (!isBot && !isClient) {
    return <div ref={ref as React.RefObject<HTMLDivElement>}></div>;
  }
  return <div ref={ref as React.RefObject<HTMLDivElement>} data-sentry-component="LazyLoadUI" data-sentry-source-file="index.tsx">{isVisible ? children : skeleton}</div>;
};
export default LazyLoadUI;