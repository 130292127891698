"use client";

import { ReactNode, useEffect, useState } from "react";
import { useIntersectionObserver } from "@/shared/hooks/useIntersectionObserver";
interface LazyLoadSectionProps {
  children: (data: any) => ReactNode;
  skeleton: ReactNode;
  loadData: () => Promise<any>;
  isBot?: boolean;
  initialData?: any;
  emptyBlock?: ReactNode | false;
}

/**
 * Компонент для ленивой загрузки данных при скролле
 * @param children Функция рендеринга, которая получает загруженные данные
 * @param skeleton Компонент-скелетон для отображения во время загрузки
 * @param loadData Функция для загрузки данных
 * @param isBot Флаг, указывающий, является ли пользователь ботом
 * @param initialData Начальные данные (если они уже загружены для бота)
 */
const LazyLoadSection = (props: LazyLoadSectionProps) => {
  const {
    children,
    skeleton,
    loadData,
    isBot = false,
    initialData = null,
    emptyBlock = false
  } = props;
  const [ref, isIntersecting] = useIntersectionObserver({
    threshold: 0.1
  });
  const [data, setData] = useState<any>(initialData);
  const [isLoading, setIsLoading] = useState(!(isBot && initialData && (Array.isArray(initialData) ? initialData.length > 0 : Object.keys(initialData).length > 0)));
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  useEffect(() => {
    if (initialData && isBot && (Array.isArray(initialData) ? initialData.length > 0 : Object.keys(initialData).length > 0)) {
      setData(initialData);
      setIsLoading(false);
    }
  }, [initialData, isBot]);
  useEffect(() => {
    if (isIntersecting && isLoading && !hasAttemptedLoad) {
      setHasAttemptedLoad(true);
      loadData().then(result => {
        setData(result);
        setIsLoading(false);
      }).catch(error => {
        console.error("Error loading data:", error);
        setIsLoading(false);
      });
    }
  }, [isIntersecting, isLoading, loadData, hasAttemptedLoad]);
  return <div ref={ref as React.RefObject<HTMLDivElement>} data-sentry-component="LazyLoadSection" data-sentry-source-file="index.tsx">
      {!isBot && !isClient ? skeleton : isLoading ? skeleton : children(data)}
    </div>;
};
export default LazyLoadSection;