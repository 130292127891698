import styles from "./index.module.scss";
import SkeletonLoader from "@/shared/components/skeleton-loader";
const BlogsSkeleton = () => {
  return <section className={styles.section} data-sentry-component="BlogsSkeleton" data-sentry-source-file="index.tsx">
      <h2 className={"title"}>Блог</h2>
      <div className={styles.blogs}>
        {Array.from({
        length: 3
      }).map((_, i) => <div className={styles.blog} key={i + "_blog_elem_skeleton"}>
            <SkeletonLoader containerTag={"div"} width={"100%"} height={""} borderRadius={8} mb={8} className={styles.image} />
            <SkeletonLoader containerTag={"div"} width={"70%"} height={16} borderRadius={4} mb={8} />
            <SkeletonLoader containerTag={"div"} width={"100%"} height={16} borderRadius={4} mb={8} />
            <SkeletonLoader containerTag={"div"} width={"40%"} height={16} borderRadius={4} />
          </div>)}
      </div>
    </section>;
};
export default BlogsSkeleton;